import env from "../environment";
import { ReportSearchTerms } from "models/ReportSearchTerms";
import { SearchResult } from "models/SearchResult";
import { ReportViewModelDto } from "models/ReportViewModelDto";
import { fetchWithAccessToken, fetchFileWithAccessToken } from "./fetch";

export class AllKpiReportService {
    kpiReportServiceUrl = `${env.apiBaseUrl}/kpi/v1/all`;

    report = (reportSearchTerms: ReportSearchTerms): Promise<SearchResult<ReportViewModelDto>> => {  
        return fetchWithAccessToken(`${this.kpiReportServiceUrl}/report?${reportSearchTerms.toQry()}`);
    }

}
